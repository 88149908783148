import { computed, reactive } from '@nuxtjs/composition-api';
import {
  FinancingMetadata,
  FramesizeMetadata,
  StateInterface,
  UseUiStateInterface,
} from '~/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
  isCartSidebarOpen: false,
  isWishlistSidebarOpen: false,
  isLoginModalOpen: false,
  isFramesizeModalOpen: false,
  isNewsletterModalOpen: false,
  isCategoryGridView: true,
  isWishlistGridView: true,
  isFilterSidebarOpen: false,
  isMobileMenuOpen: false,
  isBikeFinderModalOpen: false,
  framesizeMetadata: null,
  isFinancingModalOpen: false,
  financingMetadata: null,
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {
  const toggleMobileMenu = () => {
    state.isMobileMenuOpen = !state.isMobileMenuOpen;
  };

  const toggleCartSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isCartSidebarOpen = !state.isCartSidebarOpen;
  };

  const toggleWishlistSidebar = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
  };

  const toggleLoginModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isLoginModalOpen = !state.isLoginModalOpen;
  };

  const toggleFramesizeModal = (framesizeMetadata?: FramesizeMetadata | null) => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    const isModalCurrentlyOpen = state.isFramesizeModalOpen;

    if (isModalCurrentlyOpen) {
      // modal gets closed - delete metadata
      state.framesizeMetadata = null;
    } else {
      state.framesizeMetadata = framesizeMetadata ? { ...framesizeMetadata } : null;
    }
    state.isFramesizeModalOpen = !state.isFramesizeModalOpen;
  };

  const toggleNewsletterModal = () => {
    state.isNewsletterModalOpen = !state.isNewsletterModalOpen;
  };

  const toggleFinancingModal = (financingMetadata?: FinancingMetadata | null) => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    const isModalCurrentlyOpen = state.isFinancingModalOpen;

    if (isModalCurrentlyOpen) {
      // modal gets closed - delete metadata
      state.financingMetadata = null;
    } else {
      state.financingMetadata = financingMetadata ? { ...financingMetadata } : null;
    }
    state.isFinancingModalOpen = !state.isFinancingModalOpen;
  };

  const changeToCategoryGridView = () => {
    state.isCategoryGridView = true;
  };
  const changeToCategoryListView = () => {
    state.isCategoryGridView = false;
  };

  const changeToWishlistGridView = () => {
    state.isWishlistGridView = true;
  };

  const changeToWishlistListView = () => {
    state.isWishlistGridView = false;
  };
  const toggleFilterSidebar = () => {
    state.isFilterSidebarOpen = !state.isFilterSidebarOpen;
  };

  const toggleBikeFinderModal = () => {
    if (state.isMobileMenuOpen) toggleMobileMenu();
    state.isBikeFinderModalOpen = !state.isBikeFinderModalOpen;
  };

  return {
    isMobileMenuOpen: computed(() => state.isMobileMenuOpen),
    isCartSidebarOpen: computed(() => state.isCartSidebarOpen),
    isWishlistSidebarOpen: computed(() => state.isWishlistSidebarOpen),
    isLoginModalOpen: computed(() => state.isLoginModalOpen),
    isFramesizeModalOpen: computed(() => state.isFramesizeModalOpen),
    isNewsletterModalOpen: computed(() => state.isNewsletterModalOpen),
    isCategoryGridView: computed(() => state.isCategoryGridView),
    isWishlistGridView: computed(() => state.isWishlistGridView),
    isFilterSidebarOpen: computed(() => state.isFilterSidebarOpen),
    isBikeFinderModalOpen: computed(() => state.isBikeFinderModalOpen),
    framesizeMetadata: computed(() => state.framesizeMetadata),
    isFinancingModalOpen: computed(() => state.isFinancingModalOpen),
    financingMetadata: computed(() => state.financingMetadata),
    toggleMobileMenu,
    toggleCartSidebar,
    toggleWishlistSidebar,
    toggleLoginModal,
    toggleFramesizeModal,
    toggleNewsletterModal,
    changeToCategoryGridView,
    changeToCategoryListView,
    changeToWishlistGridView,
    changeToWishlistListView,
    toggleFilterSidebar,
    toggleBikeFinderModal,
    toggleFinancingModal,
  };
}

export default useUiState;
export * from './useUiState';
